import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import { HelmetDatoCms } from "gatsby-source-datocms";

const Project = ({ data: { project } }) => {
    const tags = project.tags.split(",").map((tag) => String(tag).trim());
    const projectIframe = project.iframe && JSON.parse(project.iframe);
    return (
        <Layout>
            <HelmetDatoCms seo={project.seoMetaTags}>
                <meta
                    property="keywords"
                    content={project.tags || "sntsdev, front-end programming"}
                />
            </HelmetDatoCms>
            <div className="single-project">
                <h1>{project.title}</h1>
                <h2>{project.subtitle}</h2>
                <div
                    className="single-project-info"
                    dangerouslySetInnerHTML={{
                        __html: project.body,
                    }}
                />
                <ul className="single-project-tags">
                    {tags.map((tag, idx) => (
                        <li key={idx}>{tag}</li>
                    ))}
                </ul>
                <ul className="single-project-images">
                    {project.images.map(({ localImage }, idx) => (
                        <li key={idx}>
                            <GatsbyImage
                                image={
                                    localImage.childImageSharp.gatsbyImageData
                                }
                                alt={project.title}
                            />
                        </li>
                    ))}
                </ul>
                {project.externalLink && (
                    <a
                        className="visit-project"
                        href={project.externalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Visit Project
                    </a>
                )}
                {projectIframe && projectIframe.src && (
                    <iframe
                        title={project.title}
                        className="single-project-iframe"
                        src={projectIframe.src}
                        width={projectIframe.width}
                        height={projectIframe.height}
                    ></iframe>
                )}
            </div>
        </Layout>
    );
};

export default Project;

export const query = graphql`
    query ProjectQuery($slug: String!) {
        project: datoCmsProject(slug: { eq: $slug }) {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            slug
            tags
            title
            subtitle
            externalLink
            images {
                localImage {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            body
            iframe
        }
    }
`;
